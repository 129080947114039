import { FC } from 'react';

import { COLOR, ColorCode } from '../constants';

export const cssColor = (colorCode: ColorCode) => `var(--${colorCode})`;

export const CssColors: FC = () => {
  return (
    <style>
      {':root {'}
      {
        Object.entries(COLOR).map(([key, value]) => {
          return `--${key}: ${value};`;
        })
      }
      {'}'}
    </style>
  );
};