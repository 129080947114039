import { HEX } from '../../types';

const BROLIS_LIGHT_BLUE = '#6789A5';
const r2 = '#FF7A00';
const b2 = '#EDEFF3';

export const COLOR = {
  b1: '#FFFFFF',
  b2,
  b3: '#F4F7FB',
  brolisDarkBlue: '#004B78',
  brolisLightBlue: BROLIS_LIGHT_BLUE,
  brolisYellow: '#E1BE00',
  chartGreen: '#59C047',
  chartGridLine: b2,
  chartLinePrimary: '#2063A6',
  chartLineSecondary: '#1794D2',
  chartLineTertiary: r2,
  chartRed: '#DB4A4A',
  diseaseMfd: '#FF0B9D',
  diseaseMfdLow: '#FF80CC',
  diseaseNeb: '#4200FF',
  diseaseNebLow: '#6E7DFF',
  hoverColor: '#BECEDA',
  j1: '#5C5C5C',
  m4: '#124583',
  m5: '#476074',
  p1: '#949494',
  p2: '#4E4E4E',
  primary: BROLIS_LIGHT_BLUE,
  r1: '#E30000',
  r2,
  v1: '#BD00FF',
  z1: '#09BF3C',
} as const;

export type ColorCode = keyof typeof COLOR;

// USE getDiseaseColor() instead direct access
export const ACUTE_COLOR: HEX = '#FFA800';
export const HEALTHY_COLOR = COLOR.z1;
export const OTHER_COLOR: HEX = '#6789A5';

export const ALERT_WARNING_MESSAGE_TEXT_COLOR = '#5F2B01';
export const ALERT_WARNING_MESSAGE_BACKGROUND_COLOR = '#FDF0E6';

export const TABLE_ZEBRA_HIGHLIGHT_COLOR = '#F4F7FB';
