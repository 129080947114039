import { FC, PropsWithChildren, ReactNode, Suspense } from 'react';

import { TheErrorBoundary } from '../../App/error-logging/error-boundary';

export const LazyComponentLoader: FC<PropsWithChildren<{ fallback?: ReactNode; }>>
  = ({ children, fallback }) => {
    return (
      <TheErrorBoundary>
        <Suspense fallback={fallback} children={children} />
      </TheErrorBoundary>
    );
  };